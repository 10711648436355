import React, { FC } from 'react';
import { Toast } from 'react-bootstrap';
import { greenCheckIcon, closeIcon } from '../../assets';
import { Spinner } from 'react-bootstrap';

import './styles.scss';

interface ToastLoaderProps {
  errorMessage?: string;
  loadingMessage?: string;
  successMessage?: string;
}

const ToastLoader: FC<ToastLoaderProps> = ({ errorMessage, loadingMessage, successMessage }) => {
  return (
    <div className="toast-upper-container">
      <Toast>
        <Toast.Body className="d-flex">
          {errorMessage || successMessage ? (
            <>
              <span style={{ marginRight: '20px' }}>{errorMessage ? closeIcon : greenCheckIcon}</span>{' '}
              {errorMessage || successMessage}
            </>
          ) : (
            <>
              <Spinner animation="border" role="status" className="spinner-border" style={{ marginRight: '20px' }} />{' '}
              {loadingMessage}
            </>
          )}
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default ToastLoader;
