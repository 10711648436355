import React, { FC } from 'react';

import { MiniCardTypes } from '../../components/types';
import './styles.scss';

const App: FC<MiniCardTypes> = ({ title }) => {
  return (
    <div className="mini-card-wrapper">
      <span className="dot mr-1"></span> <span>{title}</span>
    </div>
  );
};

export default App;
