import React, { FC } from 'react';

import CustomCard from '../custom-card';
import MiniCard from '../mini-card';

import './styles.scss';

interface IAppProps {
  data: any;
}

const App: FC<IAppProps> = ({ data }) => {
  return (
    <div className="result-wrapper">
      <div className="main-wrapper">
        <div className="d-flex flex-wrap aligin-items-center justify-content-center">
          <MiniCard title={`${data?.wordCount} words found`} />
          <MiniCard title={`${data?.charCount} letters found`} />
          <MiniCard title={data?.stats?.text} />
        </div>

        <div className="result-card-wrapper">
          <CustomCard title="Non-Common Keywords" dataArray={data?.nonCommon} />
          <CustomCard title="All Keywords" dataArray={data?.allWords} />
        </div>
      </div>
    </div>
  );
};

export default App;
