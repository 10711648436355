import React, { FC } from 'react';
import { InputTypes } from '../types';
import './style.scss';

const Input: FC<InputTypes> = ({
  type,
  value,
  placeholder,
  disabled,
  required,
  error,
  ref,
  label,
  className,
  leftIcon,
  rightIcon,
  onChange,
  id,
  name,
  defaultValue,
  dataUrl,
  onKeyPress,
  onClick,
}) => {
  return (
    <div className="custom-input">
      {label && (
        <label className="label" htmlFor={label}>
          {label}
        </label>
      )}
      <div className="input-wrapper">
        {leftIcon && (
          <button onClick={onClick} className="icon left">
            {leftIcon}
          </button>
        )}
        {rightIcon && (
          <button onClick={onClick} className="icon right">
            {rightIcon}
          </button>
        )}
        <input
          data-url={dataUrl}
          id={id || label || ''}
          ref={ref ? ref : null}
          type={type ? type : 'text'}
          placeholder={placeholder || ''}
          value={value}
          disabled={disabled ? true : false}
          required={required ? required : false}
          className={`input-field ${error ? 'error-border' : ''} ${leftIcon ? 'left-icon' : ''} ${
            rightIcon ? 'right-icon' : ''
          } ${className || ''}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
          name={name}
          onKeyPress={onKeyPress}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};

export default Input;
