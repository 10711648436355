import React, { FC } from 'react';
import './styles.scss';
import '../../styles/scroll.scss';

interface CustomCardProps {
  title: string;
  dataArray: { word: string; count: string }[];
}

const CustomCard: FC<CustomCardProps> = ({ title, dataArray }) => {
  return (
    <div className="custom-card-wrapper">
      <div className="custom-card-header">
        <h5 className="title">{title}</h5>
        <div className="custom-br" />

        <div className="stats-header">
          <span className="title-secondary">Keywords</span>
          <span className="title-secondary">Quantity</span>
        </div>
      </div>
      <div className="custom-card-body">
        <div className="stats-body">
          <div className="word">
            {dataArray.map((el: { word: string; count: string }) => {
              if (el.word.length > 20) {
                return (
                  <div title={el.word} className="text-truncate inner">
                    {el.word}
                  </div>
                );
              }
              return el.word + ' ';
            })}
          </div>
          <div className="count">
            {dataArray.map((el: { word: string; count: string }) => {
              return el.count + ' ';
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomCard;
