import React, { FC, FormEvent, KeyboardEvent, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { homeLogo, searchIcon, homeLogoWithText } from '../assets';

import { isValidUrl, isValidDomain } from '../constants';
import Result from '../components/result';

import Input from '../components/input';
import ToastLoader from '../components/toast-loader';
import Layout from '../components/layout';
import CustomModal from '../components/custom-modal';
import CTA from '../components/cta';
import './styles.scss';

const App: FC = () => {
  const [loading, setLoading] = useState(false);
  const [isData, setIsData] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [inputVal, setInputVal] = useState('');
  const [responseData, setResponseData] = useState([] as any);

  const [toasterInfo, setToasterInfo] = useState({
    show: false,
    errorMessage: '',
    loadingMessage: '',
    successMessage: '',
  });

  const handleToastShow = (show: boolean, errorMessage: string, loadingMessage: string, successMessage: string) => {
    setToasterInfo({ show: show, errorMessage, loadingMessage, successMessage });
  };

  const handleEnterKeyPress = (e: KeyboardEvent<FormEvent>) => {
    if (e.key === 'Enter') {
      handleRequest(inputVal.trim());
    }
  };

  const modalToggle = () => {
    setModalOpen(prevState => !prevState);
  };

  const handleNavigate = () => {
    navigate('/');
    setResponseData([]);
    setIsData(false);
  };

  const handleRequest = (url: string) => {
    setResponseData([]);
    setIsData(false);
    if (url && isValidUrl(url) && isValidDomain(url)) {
      setLoading(true);
      handleToastShow(true, '', 'Processing...', '');

      axios
        .post(`${process.env.GATSBY_API_URL}`, {
          url,
        })
        .then(res => {
          //@ts-ignore
          if (res && res.data && res.data.message === 'Permission denied') {
            setModalOpen(true);
            handleToastShow(false, '', '', '');
            setLoading(false);
            setIsData(false);
            setResponseData([]);
            return;
          } else {
            setResponseData(res?.data);
            setLoading(false);
            handleToastShow(true, '', '', 'Done');
            setIsData(true);
          }
        })
        .catch(err => {
          setIsData(false);
          setLoading(false);
          setModalOpen(true);
          handleToastShow(false, '', '', '');
        });
    } else handleToastShow(true, 'Please enter a valid URL', '', '');
  };

  useEffect(() => {
    let timeOutid: ReturnType<typeof setTimeout>;
    if (toasterInfo.show && (toasterInfo.errorMessage || toasterInfo.successMessage)) {
      timeOutid = setTimeout(() => {
        handleToastShow(false, '', '', '');
      }, 2000);
    }
    return () => clearTimeout(timeOutid);
  }, [toasterInfo]);

  return (
    <Layout
      title={'Inspect Content'}
      metaName={'description'}
      metaContent={
        'Specify a page URL, get words and letters count, find keywords and keyword count. See the average reading time of the page text.'
      }
    >
      {isData && (
        <div className="logo-wrapper">
          <span onClick={handleNavigate}>{homeLogoWithText}</span>
        </div>
      )}
      <section style={{ marginTop: isData ? '0' : '15vh' }} className="homepage ">
        <div className="homepage__container">
          {!isData && (
            <>
              <span>{homeLogo}</span>

              <div className="header-text">
                <span className="name">inspect</span>
                <span className="name sec">content</span>
              </div>
              <h1 className="homepage__tagline">
                Specify a page URL, get words and letters count, find keywords and keyword count. See the average
                reading time of the page text.
              </h1>
            </>
          )}
          <div className="search-wrapper">
            <Input
              onClick={() => handleRequest(inputVal.trim())}
              onKeyPress={handleEnterKeyPress}
              placeholder="Enter URL..."
              onChange={e => setInputVal(e.toLowerCase())}
              rightIcon={loading ? <Spinner animation="border" role="status" /> : searchIcon}
            />
          </div>

          <div>{isData && <Result data={responseData} />}</div>
        </div>

        {toasterInfo.show && (
          <ToastLoader
            errorMessage={toasterInfo.errorMessage}
            loadingMessage={toasterInfo.loadingMessage}
            successMessage={toasterInfo.successMessage}
          />
        )}
        <CTA />
      </section>
      <CustomModal show={modalOpen} onHide={modalToggle} />
    </Layout>
  );
};

export default App;
